.views_hide{
    cursor: not-allowed;
    opacity: 0.5;
    
}

.views{
    cursor: pointer;
    opacity: 1;
}


.disabled {
    pointer-events:none;
    cursor: not-allowed;
    opacity: 0.5;

  }

  .cardwithclose{
    justify-content: space-between;
  }

@media (min-width:700px ) and (max-width:2600px){
    /* .details{
        width:60% !important
    } */
}