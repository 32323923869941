body{
  font-family:  'Gilroy', sans-serif;
  overflow-x: hidden;
  /* background-color: #F5F6F8; */
  background-color: red;

}
.p-2rem{
  padding: 2rem;
}
.main-title{
  color: #334D6E;
  font-size: 18px;
  font-weight: 600;
}
.px-2rem{
  padding-left: 2rem;
  padding-right: 2rem;
}
.text-pink{
  color: #EE307F;
}
hr.grey-stripes{
  border-top: 1px dashed #D3D8DD !important;
}
hr.pink{
  border-bottom: 1px solid #FFBBD7 !important;
  background-color: transparent;
  margin: .5rem 0;
}
hr:last-child{
  display: none;
}
.card{
  border-radius: 4px !important;
}
.card .card-header{
  border-bottom: 1px solid #EBEFF2;
}
.card-header .d-flex select{
  color: #EE307F;
  font-size: 14px;
}
.grouped-input span.fa-caret-down{
  right: 0;
}
/* top-bar */
header.top-bar{
  position: sticky;
  top: 0;
  z-index: 1000;
}
.top-bar nav{
  background-color: #fff;
  border-bottom: 1px solid #EBEFF2;
}
nav .notification-bell span.circle{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #2450B1;
  top: 0;
  right: 0;
}
nav .notification-bell i{
  font-size: 20px;
  color: #C2CFE0;
}
.top-bar .navbar-brand{
  margin-right: 3.5rem;
}
.account-dropdown .avatar-dropdown-image img{
  width: 32px;
  height: 32px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
nav .account-dropdown .name_and_mail h6{
  font-size: 14px;
  font-weight: 600;
  color: #192A3E;
}
nav .account-dropdown .name_and_mail h6 small{
  font-size: 10px;
  font-weight: 400;
  color: #707683;
  line-height: 14px;
}
nav .account-dropdown .dropdown-menu{
  right: 0;
  left: auto;
  top: 50px;
}
nav .search-group input{
  font-size: 14px;
  color: #90A0B7;
  max-width: 105px;
}
nav .search-group span i{
  color: #C2CFE0;
}
nav .date-collapse p{
  color: #848B94;
  font-size: 12px;
  font-weight: 500;
}
nav .date-collapse i{
  color: #848B94;
}
@media(max-width:768px){
  .top-bar .navbar-brand{
      display: none;
  }
  nav .search-group input{
      max-width: 100%;
  }
}
/* Sidebar */
a[aria-controls=mobile-sidebar] i.fa-bars{
  color: #EE307F;
}
.sidebar-wrap .nav-item a ,.sidebar-wrap .sub-menu a{
  color: #334D6E;
  font-size: 13px;
  font-weight: 600;
}
.sidebar-wrap .nav-item a.nav-link i{
  font-size: 18px;
}
.sidebar-wrap .nav a.nav-link {
  display: flex;
  align-items: center;
}
.desktop-sidebar{
  background-color: #FFFFFF;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  height: 100%;
  top: 60px;
  position: fixed;
}
.sidebar-wrap {
  position: fixed;
  width: 16%;
}
.sidebar-wrap .name_and_mail h6{
  color: #334D6E;
}
.sidebar-wrap .name_and_mail h6 small{
  color: #848B94;
  font-size: 14px;
  font-weight:400;
}
.offcanvas-body .sidebar-wrap .date-collapse p{
  color: #334D6E;
  font-size: 13px;
  font-weight: 600;
}
.offcanvas-body .sidebar-wrap .date-collapse i{
  color: #EE307F;
}
.sidebar-wrap .nav-link.active{
  color: #EE2A7B;
}
.sidebar-wrap .nav-link.active i{
  color: #EE2A7B;
}
/* Content */
.follow-up-wrap small select{
  font-size: 14px;
  color: #2450B1;
  font-weight: 500;
}
.follow-up-image img{
  height: 160px;
  width: 225px;
  object-fit: contain;
}
.follow-up-count a.btn-blue{
  min-height: 38px;
  background-color: #167FFC;
  border-radius: 2px;
  text-align: center;
  line-height: 38px;
  display: block;
  text-decoration: none;
  font-weight: 600;
  font-size: 15px;
  color: #fff;
}
.custom-shadow{
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}
.card-header h6{
  color: #192A3E;
  font-size: 15px;
  font-weight: 600;
}
.card .calls-content .calls-chart svg{
  width: 100%;
  height: 100%;
  max-width: 140px;
}
.card .calls-content .chart-bulletin ul li{
  color: #1C1C1C;
  font-size: 14px;
}
.card .calls-content .chart-bulletin ul li:not(:last-child){
  margin-bottom: 1rem;
}
.card .calls-content .chart-bulletin ul li:nth-child(1)::marker{
  color: #3CABDB;
}
.card .calls-content .chart-bulletin ul li:nth-child(2)::marker{
  color: #FF607C;
}
.card .calls-content .chart-bulletin ul li:nth-child(1)::marker{
  color: #5A82B5;
}
.calls-status-wrap .right.calls-count h2{
  color: #352D30;
}
.calls-status-wrap .completed-calls-wrap {
  background-color: #F7FFFA;
  /* border: 2px solid #2ED47A !important; */
}
.calls-status-wrap .non-attended-calls-wrap  {
  background-color: #FFF7F8;
  /* border: 2px solid #FE3358 !important; */
}
.calls-status-wrap .left h6{
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card .calls-logs-content{
  max-height: 203px;
  overflow: auto;
}
.helpdesk-dash-table td{
  text-align: center;
  padding: .3rem 1rem;
  border-color: #EBEFF2;
  border-width: 1px;
  color: #434142;
  font-size: 12px;
  font-weight: 500;
}
.helpdesk-dash-table tbody tr:first-child td{
  padding-top: 2rem;
}

/* list-title card */
.list-card-head{
  background-color:#FFFFFF;
  border: 1px solid #FFACCF;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.18);
  border-radius: 8px !important;
}
.head-menu-buttons a{
  border: 1px solid #FBE2EC;
  text-decoration: none;
  color: #334D6E;
  min-height: 25px;
  display: block;
  text-align: center;
  line-height: 25px;
  font-size: 12px;
  border-radius: 5px;
}
/* table */
.table-wrap{
  overflow: auto;
}
.table-wrap table{
  background-color: #FFFFFF;
}
.table-wrap th{
  color: #334D6E;
  font-size: 13px;
  font-weight: 600;
}
.table-wrap td{
  font-weight: 500;
  font-size: 12px;
  color: #1c1c1c;
}
.table-wrap td .name_and_mail h6{
  font-weight: 500;
  font-size: 12px;
  color: #1c1c1c;
}
.table-wrap td,.table-wrap th{
  padding: .5rem 1rem
}
.table-wrap table thead{
  border-bottom: 1px solid #FFE7F1;
}
.table-wrap table tbody tr:not(:last-child){
  border-bottom: 1px solid #E4E5E8;
}
.table-wrap tbody td a{
  color: #EE2A7B;
  text-decoration: none;
}
.table-wrap .account-image-details img{
  width: 24px;
  height: 24px;
  object-fit: contain;
  border-radius: 50%;
  object-position: center;
}
/* customer basic details */
.customer-card-details-wrap label{
  color: #334D6E;
  font-size: 10px;
}
.customer-card-details-wrap input,.customer-card-details-wrap select{
  background-color: #F5F5F5;
  border: 1px solid #FFBBD7;
  border-radius: 4px !important;
  font-size: 12px;
}
.customer-card-details-wrap .btn-primary{
  font-weight: 600;
  font-size: 12px;
  background-color: #EE307F;
  border-radius: 5px !important;
  border: none !important;
  max-width: 124px;
  width: 100%;
}
.customer-card-details-wrap .card{
  border-radius: 5px !important;
}
.customer-card-details-wrap .card .card-header h5{
  color: #334D6E;
  font-weight: 600;
  font-size: 15px;
}
.customer-card-details-wrap .card .card-header,.timeline-card-wrap .card-header{
  border-bottom-color:#FFE4EF !important;
}
.right-top{
  position: sticky;
  top: 58px;
}
.list-of-customers .table-wrap{
  max-height: 646px;
}
/* call-follow-status */
.call-followup-status-wrap .card-header h6,.timeline-card-wrap h6,.followup-Ques-wrap h6{
  color: #334D6E;
  font-size: 15px;
  font-weight: 600;
}
.call-followup-status-wrap select{
  font-size: 12px;
  max-width: 152px;
  width: 100%;
  padding: 10px 16px;
  margin: 0 auto;
  border: 1px solid #FFB6D4;
  border-radius: 5px;
}
/* followup questions */
ul.questions-followup{
  list-style:decimal;
}
ul.questions-followup li a{
  font-size: 15px;
  color: #434142;
  font-weight: 500;
  text-decoration: none;
}
ul.questions-followup li::marker{
  color: #434142;
  font-weight: 600;
}
.followup-Ques-wrap .btn-primary{
  font-weight: 600;
  font-size: 12px;
  background-color: #EE307F;
  border-radius: 5px !important;
  border: none !important;
  max-width: 87px;
  width: 100%;
}
/* timeline-card */
.timeline-card-wrap .timeline-content small{
  color: #434142;
  font-size: 10px;
  font-weight: 500;
}
.timeline-card-wrap .timeline-content h6 a{
  color: #334D6E;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}
/* notifications */
.notifications-image  h6{
  color: #0085FF;
  font-weight: 500;
  display: block;
  height: 48px;
  width: 48px;
  line-height: 48px;
  text-align: center;
  border-radius: 50%;
  background: linear-gradient(0deg, rgba(0, 133, 255, 0.1), rgba(0, 133, 255, 0.1)), #FFFFFF;
}
.notifications-image  img{
  height: 48px;
  width: 48px;
  object-fit: cover;
  object-position: center;
}
.notifications-details h6 span{
  color: #969696;
  font-size: 13px;
  font-weight: 400;
}
.notifications-details h6 {
  color: #334D6E;
  font-weight: 600;
  word-wrap: anywhere;
}
.notifications-details p {
  color: #585757;
  margin-bottom: 0;
  word-wrap: anywhere;
}
.notifications-content-wrap hr {
  background-color: #E8E8E8 !important;
  opacity: 1;
}
/* login */

.login-card-welcome-title h4 {
  font-weight: 800;
  font-size: 60px;
  color: #ee307f;
  line-height: 50px;
}
.login-card-welcome-title h4 span {
  font-weight: 700;
  font-size: 30px;
  color:   #434142;
}
.login-content .input-group {
  background-color: #F5F5F5;
  border: 1px solid #fbe2ec;
  border-radius: 5px;
  position: relative;
}
.login-content .input-group.custom-input span.error{
  position: absolute;
  top: 62px;
  background-color: transparent !important;
  font-size: 12px !important;
  font-weight: 600;
  width: auto !important;
}
.login-content input {
  font-size: 14px;
  color: #334D6E;
}
.login-content input:focus {
  box-shadow: none;
}
.custom-input span {
  background-color: #fff;
}
.login-content .remmemberme label{
  font-size: 14px;
}
.login-content .remmemberme input[type=checkbox]{
  margin-top: 0;
  font-size: 16px;
}
.custom-input span, .custom-input input {
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
}
.primary-btn {
  background-color: #ee307f;
  color: #fff !important;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  height: 40px;
  font-weight: 700;
  font-size: 14px;
  color: #FFF;
}
.primary-btn:focus{
  background-color: #ee307f !important;
  color: #fff !important;
}
.login-button-div a {
  text-decoration: none;
  color: #ee307f;
  font-weight: 600;
}
.login-button-div small {
  color: #1C1C1C;
}
.login-img-wrap img {
  object-fit: contain;
  height: 100vh;
  width: 100%;
}
.login-img-wrap{
  background-color: #D9F1FF;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.login-content .input-group span i{
  color: #c1c1c1;
}
.logo-wrap img {
  width: 160px;
}
.login-content .input-group span i.bi{
  font-size: 18px;
}
.login-content .custom-input input{
  padding-left: 0;
}
  .login-content .forgot-pass small a{
      color: #ee307f;
      font-weight: 500;
  }
@media (max-width: 992px){
.login-wrap{
  background-color: transparent;
}
.login-img-wrap{
  background-color: #D9F1FF;
}

}
@media(max-width:768px){
  .login-img-wrap{
      display: none;
  }
}


/* 05-01-2023 */
ul.questions-followup li label,ul.questions-followup li textarea{
  font-size: 15px;
  color: #434142 !important;
  font-weight: 500;
}
ul.questions-followup li .collapse,ul.questions-followup li .collapsing{
  border-radius: 5px;
  margin-top: .5rem;
}
ul.questions-followup li .collapse a:not(:last-child){
  border-bottom: 1px solid #E4E5E8 !important;
}
ul.questions-followup li .collapse a{
  font-size: 15px;
  color: #434142 !important;
  font-weight: 500;
}
ul.questions-followup li .dropdown-toggle{
  font-size: 15px;
  color: #434142 !important;
  text-decoration: none !important;
  font-weight: 500;
}

.custom-file-upload {
  border: 1px solid #EE307F;
  /* display: inline-block; */
  padding: 6px 12px;
  cursor: pointer;
}
.topnav {
  overflow: hidden;
  background-color:"white";
}

.topnav a {
  float: left;
  color: #334D6E;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 15px;
}
.topnav a:not(:last-child){
  margin-right: .5rem;
}

.topnav a:hover {
  background-color: #FBE2EC;
  color: black;
  border-radius: 5px;
}

.topnav a.active {
  background-color: #EE307F;
  color: white;
  border-radius: 5px;
}
.head-menu-buttons .custom-file-upload {
  min-height: 25px;
  display: block;
  border-radius: 5px;
  font-size: 12px;
  margin-bottom: 0;
  line-height: 25px;
  border: 1px solid #FBE2EC;
  padding-top: 0;
  padding-bottom: 0;
}
.agree-checkbox-wrap input[type=checkbox]{
  margin-left: 0 !important;
  position: unset;
}
.modal-btn{
  background-color: #0F52BA !important;
  border-radius: 5px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
.modal-outer .modal-title{
  color: #334D6E;
  font-size: 22px;
  font-weight: 600;
}
.modal-outer form label.form-label:not(.agree-checkbox-wrap label){
  position: absolute;
  top: -10px;
  left: 30px;
  z-index: 10;
  background-color: #fff;
  padding: 0 0.2rem;
  color: #515254;
  font-size: 12px;
  font-weight: 500;
}
.modal-outer form .select-wrap label.form-label{
  top: -9px !important;
}
.modal-outer form input[type=text],.modal-outer form input[type=name],.modal-outer form input[type=password],.modal-outer form input[type=phone],.modal-outer form input[type=email]{
  min-height: 48px;
  border-color: #E3EEFF;
}
.modal-outer span.input-group-text{
  border-color: #E3EEFF;
}
.modal-outer .css-13cymwt-control,.modal-outer .css-t3ipsp-control{
  height: 48px;
  border-color: #E3EEFF;
}

.modal-outer .css-1fdsijx-ValueContainer{
  padding: 0 1rem;
}
.modal-outer .css-hlgwow{
  padding: 0px 8px !important;
}
.modal-outer .css-qbdosj-Input,.modal-outer .css-19bb58m{
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
}
.modal-outer input:focus{
  box-shadow: none !important;
}
.list-card-head input[type=date]{
  border: none;
}
.list-card-head .css-13cymwt-control,.list-card-head .css-t3ipsp-control{
  height: 25px;
  min-height: 25px !important;
  display: flex;
  align-items: start;
  border-color: #FBE2EC;
}
.list-card-head .css-qbdosj-Input input,.list-card-head .css-1jqq78o-placeholder{
  font-size: 12px !important;
}
.list-card-head .css-1fdsijx-ValueContainer{
  padding: 0 1rem;
}
.list-card-head .css-qbdosj-Input{
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
}
.list-card-head .css-1hb7zxy-IndicatorsContainer{
   height:25px;
}
.list-card-head  .css-1dimb5e-singleValue{
  font-size: 12px;
}
.list-card-head .css-hlgwow{
  padding: 0px 8px !important;
}
.list-card-head .css-19bb58m{
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
}
.pagination li a{
  border-radius: 5px;
  color: #ee307f;
  border-color: #fbe2ec;
}
.pagination li a:hover:not(.pagination li.active a:hover){
  background-color: #fbe2ec;
  color: #ee307f;
}
.pagination li.active a{
  background-color: #ee307f;
  border: none;
  border-radius: 5px;
  color: #fff;
}
#mobile-sidebar{
  z-index: 9999;
}
.modal-body input{
  padding-top:0 !important;
  padding-bottom: 0 !important;
}
.call-followup-status-wrap #MyFollowUpTab{
  background-color: #f5f5f5;
  border: 1px solid #ffbbd7;
  border-radius: 4px !important;
  font-size: 12px;
  width: 100%;
  padding: .5rem;
}
.followup-Ques-wrap .questions-followup li{
  color: #334d6e;
  font-size: 13px;
  font-weight: 600;
}
.followup-Ques-wrap .questions-followup li span{
  margin-bottom: .5rem;
  display: block;
}
.follow-up-wrap .btn-primary{
  background-color: #ee307f !important;
  border: none !important;
}
.css-1xc3v61-indicatorContainer,.css-15lsz6c-indicatorContainer{
  padding: 0 !important;
}
.css-1cfo1cf{
  margin: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.css-1cfo1cf input{
  min-width: 100px !important;
}
.css-19bb58m input{
  min-width: 100px !important;
}
.picker{
  border-width: 0;
  text-align: center;
}