.ul-li{
    padding-left:2%;
    
}


@media screen and (max-width:500px){
    .ul-li{
       font-size:0.2px        
    }
}